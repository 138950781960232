<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid py-8">
      <div class="d-flex flex-row">
        <UserNav activePage="contactdetails" activeTab="5"></UserNav>
        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">

          <!--begin::Content-->
          <div class="flex-row-fluid ">
            <!--begin::Card-->
            <div class="card card-custom">
              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                  <div class="card-title align-items-start flex-column">
                    <h3 class="font-weight-bolder text-dark fs-15">Contact Details</h3>
                    <span class="text-dark font-weight-bold font-size-sm mt-1">Update your personal information</span>
                  </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body">
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mb-6">Coach Info</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="image-input image-input-outline" id="kt_profile_avatar">
                        <div class="image-input-wrapper" :style="{ backgroundImage: `url(${photo})` }"></div>
                        <label class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      " data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                          <i class="fa fa-pen icon-sm text-dark"></i>
                          <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"
                            @change="onFileChange($event)" />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      " data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                          <i class="ki ki-bold-close icon-xs text-dark"></i>
                        </span>
                        <span class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      " data-action="remove" data-toggle="tooltip" title="Remove avatar" @click="
                        current_photo = null;
                      form.photo = null;
                      ">
                          <i class="ki ki-bold-close icon-xs text-dark"></i>
                        </span>
                      </div>
                      <span class="form-text text-dark">Allowed file types: png, jpg, jpeg.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">First Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input ref="firstname" name="firstname" class="form-control form-control-lg form-control-solid"
                        type="text" v-model="form.firstname" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Last Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input ref="lastname" name="lastname" class="form-control form-control-lg form-control-solid"
                        type="text" v-model="form.lastname" />
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile Phone</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-phone"></i>
                          </span>
                        </div>
                        <input ref="phone" name="phone" type="text"
                          class="form-control form-control-lg form-control-solid" placeholder="Phone"
                          v-model="form.phone" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Email Address</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-at"></i>
                          </span>
                        </div>
                        <input ref="email" type="text" name="email" readonly
                          class="form-control form-control-lg form-control-solid" placeholder="Email"
                          v-model="form.email" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mt-10 mb-6">Coaching Community</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Title</label>
                    <div class="col-lg-9 col-xl-6">
                      <input ref="title" name="title" class="form-control form-control-lg form-control-solid" readonly
                        type="text" v-model="courseTitle" />
                    </div>
                  </div>
                  <div class="pb-lg-0 pb-5">
                    <div class="card-footer">
                      <button ref="kt_save_changes" class="btn btn-ingomublue mr-2">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                <!--end::Body-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import emailAddress from "@/assets/plugins/formvalidation/dist/es6/validators/emailAddress";
import phone from "@/assets/plugins/formvalidation/dist/es6/validators/phone";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: "PersonalInformation",
  components: {
    UserNav
  },
  metaInfo() {
    return {
      title: "Contact Details",
    };
  },
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      courseTitle: "",
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        photo: null,
      },
    };
  },
  mounted() {
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 4 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 7 });
    const changes_form = KTUtil.getById("kt_save_changes_form");

    this.getProgramContactDetails();

    this.fv = formValidation(changes_form, {
      fields: {
        email: {
          validators: {
            callback: {
              message: "The value is not a valid email address",
              callback: function (input) {
                const value = input.value;
                if (value === "") {
                  return true;
                }

                // I want the value has to pass both emailAddress and regexp validators
                return emailAddress().validate({
                  value: value,
                }).valid;
              },
            },
            notEmpty: {
              message: "Email is required",
            },
          },
        },
        firstname: {
          validators: {
            notEmpty: {
              message: "First name is required",
            },
          },
        },
        lastname: {
          validators: {
            notEmpty: {
              message: "Last name is required",
            },
          },
        },
        phone: {
          validators: {
            callback: {
              message: "The value is not a valid phone number",
              callback: function (input) {
                const value = input.value;
                if (value === "") {
                  return true;
                }

                // I want the value has to pass both emailAddress and regexp validators
                return phone().validate({
                  value: value,
                }).valid;
              },
            },
            notEmpty: {
              message: "Phone is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("updateContactDetails", this.form)
        .then(() => {
          setTimeout(function () {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire({
              title: "",
              text: "Your profile has been updated",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
            });
          }, 2000);
        })
        .catch((error) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire({
            title: "",
            text: error.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
          });
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: true,
      });
    });
  },
  methods: {
    ...mapActions(["getProgramContactDetails"]),
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
          this.form.photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["programCoachDetail"]),
    photo() {
      return this.current_photo == null || this.current_photo == "undefined"
        ? this.default_photo
        : this.current_photo;
    },
    coachDetails: function () {
      return this.$store.state.coach.coachDetail;
    },
  },
  watch: {
    programCoachDetail(newValue) {
      if (this.programCoachDetail.length > 0) {
        this.courseTitle = this.programCoachDetail[0].coach_list[0].title;
        this.current_photo = this.programCoachDetail[0].user.profileimage;
        if (this.programCoachDetail[0].user.profileimage !== null) {
          this.default_photo = this.programCoachDetail[0].user.profileimage;
        }
        this.form.firstname = this.programCoachDetail[0].user.firstname;
        this.form.lastname = this.programCoachDetail[0].user.lastname;
        this.form.email = this.programCoachDetail[0].email;
        this.form.phone = this.programCoachDetail[0].phone;
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>